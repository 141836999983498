<template>
    <svg
        :height="height"
        :viewBox="`0 0 ${width} ${height}`"
        :width="width"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <component v-bind="$attrs" :is="iconComponent" />
    </svg>
</template>

<script>
export default {
    name: 'AppIcon',

    props: {
        name: {
            type: String,
            required: true,
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
    },

    computed: {
        iconComponent() {
            return () => import(`./Icons/${this.name}`)
        },
    },
}
</script>
